import { useTranslation } from '@cms/i18n/client';
import { Competition } from '@common/clients/api';
import { BrandedHeading } from '@web/atoms/BrandedHeading';
import { Route } from '@web/atoms/InternalLink';
import { ResponsiveToggle } from '@web/atoms/ResponsiveToggle';
import { ShowMoreLink } from '@web/atoms/ShowMoreLink';

import { useMatchListThemeConfig } from './MatchList.config';

import styles from './MatchList.module.scss';

interface Props {
    competition: Competition;
}

export const CompetitionElement = ({ competition }: Props) => {
    const __common = useTranslation('common').t;
    const themeConfig = useMatchListThemeConfig();
    return (
        <div className={styles.competition}>
            <BrandedHeading
                as="h2"
                accentColor={themeConfig.brandedHeadingAccentColor}
                variant={themeConfig.brandedHeadingVariant}
                typography={themeConfig.brandedHeadingTypography}
            >
                {competition.tag?.title || competition.name}
            </BrandedHeading>
            {competition.tag ? (
                <ShowMoreLink route={Route.Competition} query={{ tag: competition.tag.tagSlug }}>
                    <ResponsiveToggle
                        desktop={__common('more-x', { x: competition.tag.title })}
                        mobile={__common`more`}
                    />
                </ShowMoreLink>
            ) : null}
        </div>
    );
};
