import { Color } from '@common/types/Color';
import { Typography } from '@common/types/Typography';
import { BrandedHeadingVariant, Props as BrandedHeadingProps } from '@web/atoms/BrandedHeading';
import { ThemeConfig, useThemeConfig } from '@web/hooks/useThemeConfig';

export interface MatchListThemeConfig {
    brandedHeadingAccentColor: NonNullable<BrandedHeadingProps['accentColor']>;
    brandedHeadingVariant: NonNullable<BrandedHeadingProps['variant']>;
    brandedHeadingTypography?: BrandedHeadingProps['typography'];
}

const THEME_CONFIG: ThemeConfig<MatchListThemeConfig> = {
    defaults: {
        brandedHeadingAccentColor: Color.BETTING_COLOR,
        brandedHeadingVariant: BrandedHeadingVariant.BORDER_BOTTOM,
    },
    byPlatform: {
        vi: {
            brandedHeadingAccentColor: Color.LIGHT_SECTION_BORDER,
            brandedHeadingTypography: Typography.ARTICLE_HEADING_MEDIUM,
        },
    },
};

export const useMatchListThemeConfig = () => {
    return useThemeConfig(THEME_CONFIG);
};
